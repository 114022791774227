import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/lessons', {
    method: 'get',
    params,
  });
}

export async function create(params) {
  return request('/api/lessons', {
    method: 'post',
    data: params,
  });
}

export async function update(params) {
  return request('/api/lessons', {
    method: 'put',
    data: params,
  });
}

export async function getByUser(params) {
  return request('/api/lessons/getByUser', {
    method: 'get',
    params,
  });
}

export async function getPermittedOperations(params) {
  return request('/api/lessons/permittedOperations', {
    method: 'get',
    params,
  });
}

export async function getUsers(params) {
  return request('/api/lessons/getUsers', {
    method: 'get',
    params,
  });
}

export async function getById(params) {
  return request('/api/lessons/getById', {
    method: 'get',
    params,
  });
}

export async function getLessonsDetail(params) {
  return request('/api/lessons/detail', {
    method: 'post',
    params,
  });
}

export async function getLessonsByCourse(params) {
  return request('/api/lessons/getByCourse', {
    method: 'GET',
    params,
  });
}

export async function connectLessons(payload) {
  return request('/api/lessons/connectLessons', {
    method: 'POST',
    data: payload,
  });
}

export async function getConnectedLessonsByParentLessonId(params) {
  return request('/api/lessons/getConnectedLessonsByParentLessonId', {
    method: 'GET',
    params,
  });
}

export async function getLessonsTotalDuration(params) {
  return request('/api/lessons/duration', {
    method: 'GET',
    params,
  });
}

export async function createZoomMeeting(data) {
  return request('/api/lessons/meetings', {
    method: 'post',
    data,
  });
}

export async function query4Table(params) {
  return request('/api/lessons/table', {
    method: 'get',
    params,
  });
}

export async function updateQualityNote(params) {
  return request('/api/lessons/updateQualityNote', {
    method: 'put',
    data: params,
  });
}

export async function putTeachingComplaints(payload) {
  return request('/api/teachingComplaints', {
    method: 'post',
    data: payload,
  });
}

export async function getAllMatchMaker() {
  return request('/api/teachingComplaints/matchmaker', {
    method: 'get',
  });
}

export async function updateUsage(data) {
  return request('/api/lessons/usage', {
    method: 'put',
    data,
  });
}

export async function deleteLesson(id) {
  return request(`/api/lessons/${id}`, {
    method: 'delete',
  });
}

export async function exportMothlyLessons4Uni(payload) {
  return request('/api/lessons/exportMothlyLessons4Uni', {
    method: 'post',
    data: payload,
  });
}

import { cn, utcOffset } from '@h6you/timezone-names-cn';

export const timezoneOptions = Object.entries(cn).map((elem) => {
  const [tz, zhName] = elem;
  if (tz === 'Asia/Shanghai') {
    return {
      label: `亚洲/北京 ${tz}`,
      value: tz,
    };
  }
  return {
    label: `${zhName} ${tz}`,
    value: tz,
  };
});

const frequentlyUsed = [
  'Asia/Shanghai',
  'America/Toronto',
  'America/Vancouver',
  'America/Edmonton',
  'America/Winnipeg',
  'America/New_York',
  'America/Chicago',
  'America/Los_Angeles',
  'Canada/Newfoundland',
  'America/Halifax',
  'Europe/London',
  'Australia/Sydney',
  'Australia/Perth',
  'Australia/Adelaide',
  'Pacific/Auckland',
];

export const frequentlyUsedTimezones = frequentlyUsed.map((tz) => {
  const offset = Math.round(utcOffset(tz) / 6) / 10;
  const offsetString = `${offset < 0 ? offset : `+${offset}`}`;
  //将GMT改为UTC
  if (tz === 'Asia/Shanghai') {
    return {
      label: `亚洲/北京(UTC${offsetString})`,
      value: tz,
    };
  }
  if (tz === 'America/Vancouver') {
    return {
      label: `美洲/温哥华/圣地亚哥(UTC${offsetString})`,
      value: tz,
    };
  }
  if (tz === 'America/Los_Angeles') {
    return {
      label: `美洲/西雅图/洛杉矶(UTC${offsetString})`,
      value: tz,
    };
  }
  const zhName = cn[tz];
  return {
    label: `${zhName}(UTC${offsetString})`,
    value: tz,
  };
});

export const DSTtimeZones = {
  Canada: 'America/Toronto', // 加拿大多伦多时区
  USA: 'America/New_York', // 美国东部时区
  UK: 'Europe/London', // 英国伦敦时区
  Australia: 'Australia/Sydney', // 澳大利亚悉尼时区
  'New Zealand': 'Pacific/Auckland', // 新西兰奥克兰时区
};

export const DSTcountryName = {
  Canada: '加拿大',
  USA: '美国',
  UK: '英国',
  Australia: '澳大利亚',
  'New Zealand': '新西兰',
};
